$lighten-amount: 15%;

$color-white: #FFF;
$color-black: #000;
$color-haribo-yellow: #FFEB06;
$color-haribo-red: #D8232A;
$color-haribo-blue: #005CA9;
$color-haribo-dark-blue: #004B87;
$color-haribo-orange: #FF8200;
$color-haribo-green: #78BE21;
$color-haribo-pink: #E61184;
$color-haribo-grey-1: #C7C9C7;
$color-haribo-grey-2: #7C878E;
$color-haribo-grey-3: #505759;
$color-haribo-grey-4: #EBEBEB;
$color-haribo-grey-5: #F7F7F7;

$color-maoam-yellow: #FCE029;
$color-maoam-yellow-light: lighten($color-maoam-yellow, 25%);
$color-maoam-blue: #27348B;
$color-maoam-orange: #ED6D05;
