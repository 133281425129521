/*!
	This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts:  https://www.linotype.com
	*/
@font-face {
	font-family: "VAG Rounded";
	font-weight: 300;
	font-style: normal;
	font-display: swap;
	src:
		url("/assets/fonts/vag-rounded/thin/ef89dd32-852b-44c7-a34a-9bac2b550347.woff2") format("woff2"),
		url("/assets/fonts/vag-rounded/thin/36f2e551-9bdf-47f6-afc1-308b60c0d52f.woff") format("woff"),
		url("/assets/fonts/vag-rounded/thin/c5c7ac79-f5e9-40d9-8096-a32522c01c12.ttf") format("truetype");
}

@font-face {
	font-family: "VAG Rounded";
	font-weight: 700;
	font-style: normal;
	font-display: swap;
	src:
		url("/assets/fonts/vag-rounded/bold/01df50be-e1aa-452e-84e2-b0d509606487.woff2") format("woff2"),
		url("/assets/fonts/vag-rounded/bold/7b5f33af-2082-4b59-aee5-1eb5023eaec1.woff") format("woff"),
		url("/assets/fonts/vag-rounded/bold/875eec0b-d747-412c-a30c-147c7d3e948a.ttf") format("truetype");
}

@font-face {
	font-family: "El Messiri";
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	src: url("/assets/fonts/el-messiri/ElMessiri-Regular.ttf") format("truetype");
	unicode-range: U+0600-06FF;
}

@font-face {
	font-family: "El Messiri";
	font-weight: 700;
	font-style: normal;
	font-display: swap;
	src: url("/assets/fonts/el-messiri/ElMessiri-Bold.ttf") format("truetype");
	unicode-range: U+0600-06FF;
}
