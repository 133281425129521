/**
 * font-family Mixin
 *
 * Usage:
 *
 * .class {
 *     @include font-family(madera);
 * }
 *
 * Output:
 *
 * .class {
 *     font-family: sans-serif;
 * }
 *
 * .wf-madera-loaded .class {
 *     font-family: "madera", sans-serif;
 * }
 *
 */

@mixin font-family($font, $font-weight: normal, $font-style: normal) {
	font-family: $font-family-vag-rounded;

	[lang="ar"] & {
		font-family: $font-family-el-messiri;
	}

	[lang="ja-JP"] & {
		font-family: $font-family-m-plus-rounded-1c;
	}

	@if $font-weight == thin {
		font-weight: 300;
	}
	@if $font-weight == normal {
		font-weight: 400;
	}
	@else if $font-weight == medium {
		font-weight: 500;
	}
	@else if $font-weight == bold {
		font-weight: 700;
	}
	@else if $font-weight == black {
		font-weight: 900;
	}
	@else {
		font-weight: $font-weight;
	}

	@if $font-style != normal {
		font-style: $font-style;
	}
}
