/* stylelint-disable */

html[lang="da-DK"] {
	body {
		#CybotCookiebotDialogBodyContentText {
			color: $color-white !important;

			a {
				color: $color-white !important;
				font-weight: bold;
				text-decoration: underline;
			}
		}
	}
}

body {
	#CybotCookiebotDialog,
	#CookiebotWidget {
		font-family: "VAG Rounded", sans-serif !important;
		color: $color-haribo-blue !important;
	}

	#CybotCookiebotDialogBodyButtonsWrapper button,
	#CookiebotWidget-buttons button {
		background-color: $color-white !important;
		color: $color-haribo-blue !important;
		border-color: $color-haribo-blue !important;
		border-radius: 500px !important; // to make it round with an unknown height
		@include transition(background-color 0.2s, color 0.2s);
		transition-delay: 0s !important;

		@include hover-focus() {
			background-color: $color-haribo-blue !important;
			color: $color-white !important;
		}
	}

	#CybotCookiebotDialogBodyContentTitle {
		@include font-style(headline-j2);
	}

	#CybotCookiebotDialogBodyContentText,
	#CybotCookiebotDialogDetailBodyContentTextAbout,
	#CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieTypeIntro,
	#CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieInfoDescription,
	#CybotCookiebotDialogDetailFooter {
		@include font-style(copy-b1);
		line-height: 1.4 !important;
	}

	.CybotCookiebotDialogNavItemLink {
		border-color: transparent !important;
		color: $color-haribo-blue !important;
		@include transition(color 0.2s);

		@include hover-focus() {
			color: $color-haribo-red !important;
		}

		&.CybotCookiebotDialogActive {
			pointer-events: none !important;
			background-color: $color-haribo-grey-5 !important;
		}
	}

	.CybotCookiebotDialogBodyLevelButtonDescription:hover {
		color: $color-haribo-blue !important;
	}

	.CybotCookiebotDialogBodyLevelButtonSlider {
		background-color: $color-haribo-grey-1 !important;
	}

	input:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
		background-color: $color-haribo-blue !important;
	}

	input:disabled + .CybotCookiebotDialogBodyLevelButtonSlider {
		opacity: 0.35;
	}

	.CybotCookiebotDialogDetailBulkConsentCount {
		background-color: $color-haribo-grey-1 !important;
	}

	#CybotCookiebotDialogHeader {
		border-color: $color-haribo-grey-1 !important;
	}

	#CybotCookiebotDialogNav {
		border-color: $color-haribo-grey-1 !important;
	}

	.CybotCookiebotDialogDetailBodyContentCookieContainerButton {
		@include hover-focus() {
			color: $color-haribo-blue !important;
		}
	}

	// Simple text links
	#CybotCookiebotDialogBodyContentText a,
	.CybotCookiebotDialogDetailBodyContentCookieLink,
	#CybotCookiebotDialog #CybotCookiebotDialogDetailFooter a,
	.CybotCookiebotDialogDetailBodyContentCookieProvider {
	@include font-family("VAG Rounded", bold);
		font-size: inherit;
		line-height: inherit;
		color: $color-haribo-blue !important;
		@include transition(color 0.2s);

		@include hover-focus() {
			color: $color-haribo-red !important;
		}

		.CybotExternalLinkArrow {
			width: auto;
		}

		strong {
			font-size: inherit;
			font-weight: inherit;
			line-height: inherit;
		}
	}

	.CybotCookiebotDialogDetailBodyContentCookieProvider {
		color: $color-black !important;
	}

	#CybotCookiebotDialogHeader,
	#CybotCookiebotDialogPoweredByText {
		display: none !important;
	}

	// WIDGET

	#CookiebotWidget * {
		color: $color-haribo-blue;
	}

	.CookiebotWidget-logo circle {
		fill: $color-haribo-blue !important;
	}

	.CookiebotWidget-consents-list svg {
		fill: $color-haribo-grey-1 !important;
	}

	.CookiebotWidget-approved svg {
		fill: $color-haribo-blue !important;
	}

	.CookiebotWidget-consent-details-box {
		background-color: $color-haribo-grey-5 !important;
	}

	.CookiebotWidget-show-consent-details {
		color: $color-haribo-blue !important;

		svg {
			fill: $color-haribo-blue !important;
		}

		@include hover-focus() {
			color: $color-haribo-blue !important;
		}
	}

	#CookiebotWidget-btn-withdraw[disabled] {
		opacity: 0.5 !important;
		pointer-events: none !important;
	}

	.CookiebotWidget-main-logo {
		display: none !important;
	}
}

/* stylelint-enable */
